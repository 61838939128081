import React, { useEffect, useRef, useState } from "react"
import { useDispatch } from 'react-redux';
import { graphql } from 'gatsby';
import Layout from "../components/Layout/Layout";
import Hero from '../components/Hero/Hero';
import { BlockA, BlockC1, BlockC2, BlockQuote, BlockH, BlockD } from '../components/BlockModule/BlockModule';
import { setNavColor, setNavTrigger, setNavDefaultColor, setCurrentPage } from "../actions";
import Seo from "../components/Seo/Seo";
import useWindowSize from "../utils/useWindowSize";
import HeroImage from './assets/The Adventure_image 1@2x.jpg';
import HeroImageBlur from './assets/The Adventure_image 1_blur@2x.jpg';
import HeroMobileImage from './assets/mobile/The Adventure_image 1@2x.jpg';
import HeroMobileImageBlur from './assets/mobile/The Adventure_image 1_blur@2x.jpg';
import ImageOne from './assets/The Adventure_image 2@2x.jpg';
import ImageTwo from './assets/The Adventure_image 3@2x.jpg';
import ImageThree from './assets/The Adventure_image 4@2x.jpg';
import ImageFour from './assets/The Adventure_image 5@2x.jpg';
import ImageFive from './assets/The Adventure_image 6@2x.jpg';

// markup
const AdventurePage = ({data}) => {
  const dispatch = useDispatch();
  const navTriggerRef = useRef();
  const [ Trigger, setTrigger] = useState();
  const [ width, height ] = useWindowSize();
  const document = data.allPrismicAdventure.edges[0].node.data;
  const BlockQuoteData = document.body[0].primary.quote;
  const BlockDData_1 = document.body[1].primary;
  const BlockC1Data = document.body[2].primary;
  const BlockC2Data = document.body[3].primary;
  const BlockHData = document.body[4].primary;
  const BlockDData_2 = document.body[5].primary;
  useEffect(() => {
    dispatch(setCurrentPage('/adventure'));
    setTrigger(navTriggerRef.current);
  },[]);

  return (
    <Layout trigger={Trigger} defaultColor='white' footer>
      <Seo title="Adventure"/>
        <Hero ref={navTriggerRef} desktop={{src: document.hero_image[0].image.url, placeholder: document.hero_image[0].image.url+'&w=100&blur=10&q=50'}} 
        mobile={{src: document.hero_image[1].image.url, placeholder: document.hero_image[1].image.url+'&w=100&blur=10&q=50'}} small/>
        <BlockQuote medium animated>{BlockQuoteData}</BlockQuote>
<BlockD image={{src: BlockDData_1.image.url, placeholder: BlockDData_1.image.url+'&w=100&blur=10&q=50' , alt: BlockDData_1.image.alt, width: BlockDData_1.image.dimensions.width, height: BlockDData_1.image.dimensions.height}} title={BlockDData_1.title} html={BlockDData_1.copy.html} reverse animated/>
<BlockC1 animated large image={{src: BlockC1Data.image.url, placeholder: BlockC1Data.image.url+'&w=100&blur=10&q=50' , alt: BlockC1Data.image.alt, width: BlockC1Data.image.dimensions.width, height: BlockC1Data.image.dimensions.height}} title={BlockC1Data.title} html={BlockC1Data.copy.html}/>
<BlockC2 animated image={{src: BlockC2Data.image.url, placeholder: BlockC2Data.image.url+'&w=100&blur=10&q=50' , alt: BlockC2Data.image.alt, width: BlockC2Data.image.dimensions.width, height: BlockC2Data.image.dimensions.height}} title={BlockC2Data.title} html={BlockC2Data.copy.html}/>
{ width > 900 ? <BlockH animated image={{src: BlockHData.image.url, placeholder: BlockHData.image.url+'&w=100&blur=10&q=50' , alt: BlockHData.image.alt, width: BlockHData.image.dimensions.width, height: BlockHData.image.dimensions.height}}/> : null }
<BlockD animated image={{src: BlockDData_2.image.url, placeholder: BlockDData_2.image.url+'&w=100&blur=10&q=50' , alt: BlockDData_2.image.alt, width: BlockDData_2.image.dimensions.width, height: BlockDData_2.image.dimensions.height}} title={BlockDData_2.title} html={BlockDData_2.copy.html} reverse none large={false}/>
    </Layout>
  )
}

export const query = graphql`
query AdventureQuery {
  allPrismicAdventure {
    edges {
      node {
        data {
          hero_image {
            breakpoint
            image {
              alt
              dimensions {
                height
                width
              }
              url
            }
          }
          body {
            ... on PrismicAdventureBodyBlockQuote {
              id
              primary {
                quote: copy
              }
            }
            ... on PrismicAdventureBodyBlockD {
              id
              primary {
                title
                copy {
                  html
                }
                image {
                  alt
                  dimensions {
                    height
                    width
                  }
                  url
                }
              }
            }
            ... on PrismicAdventureBodyBlockC1 {
              id
              primary {
                copy {
                  html
                }
                title
                image {
                  alt
                  dimensions {
                    height
                    width
                  }
                  url
                }
              }
            }
            ... on PrismicAdventureBodyBlockC2 {
              id
              primary {
                copy {
                  html
                }
                image {
                  alt
                  url
                  dimensions {
                    height
                    width
                  }
                }
                title
              }
            }
            ... on PrismicAdventureBodyBlockH {
              id
              primary {
                image {
                  alt
                  url
                  dimensions {
                    height
                    width
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`

export default AdventurePage